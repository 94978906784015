import { ref, computed } from 'vue'
import { useCountries } from '@/composables/useCountries'
import { useCurrentLocale } from '@/composables/useCurrentLocale'
import isoNameCountries from '@/data/iso-name-countries'
import locales from '@/lang/locales'
import { useI18nStore } from '@/stores/i18nStore'

export function usei18nMx() {
  const opened = ref(false)
  const { $i18n } = useNuxtApp()
  const { tld, locale, country } = useCurrentLocale()
  const countries = computed(() => {
    const { countries } = useCountries()
    return countries
  })
  const langCode = computed(() => {
    // return fr-FR // useful for ex. for trustpilot
    return locale + '-' + tld
  })
  const hostCountryName = computed(() => {
    // return France, Belgique
    return isoNameCountries[tld]
  })
  const availableLanguages = computed(() => {
    // return array of available languages like ['fr', 'be']
    return country?.availableLanguages?.map(lang => {
      return locales.find(locale => locale?.code?.toLowerCase() === lang?.toLowerCase())
    })
  })
  const localCompanyCode = computed(() => {
    // return SIREN, KVK or BCE
    return country?.localCompanyCode
  })
  const countryPhoneNumber = computed(() => {
    return country?.phoneNumber
  })
  const contactFormLink = computed(() => {
    return country?.contactFormLink[locale]
  })

  const init = async () => {
    const route = useRoute()
    if (route.query?.location_modal_disabled == 1) {
      const locationModalDisabled = useCookie('x-location_modal_disabled')
      locationModalDisabled.value = '1'
    }
  }

  const afterSwitchLocale = () => {
    // can be define in component after locale switching
    return null
  }

  const switchLocale = async (lang, fromUserAction = false) => {
    $i18n.setLocale(lang)
    const langCookie = useCookie('x-lang', { maxAge: 60 * 60 * 24 * 365, domain: `.${country?.host}` })
    langCookie.value = lang
    afterSwitchLocale()
    opened.value = false
    const i18nStore = useI18nStore()
    i18nStore.locale = lang
    let langCode = new Set()
    langCode.add(tld?.toLowerCase())
    langCode.add(lang?.toLowerCase())
    langCode = Array.from(langCode)
    langCode = langCode?.join('-')
    if (fromUserAction && import.meta.client) {
      const alternates = document?.querySelectorAll('link[rel=alternate]')
      const alternate = alternates?.find(link => {
        return link?.hreflang?.toLowerCase() === langCode
      })
      if (alternate) {
        window.location.replace(alternate.href)
      }
    }
    refreshNuxtData()
    if (import.meta.client) {
      const html = document.querySelector('html')
      html.lang = langCode
    }
  }

  const getCountry = value => {
    // return plain country depending on value
    return countries?.find(x => x?.name === value || x?.name?.includes(name) || x?.iso === value || x?.code === value)
  }

  return {
    init,
    afterSwitchLocale,
    getCountry,
    switchLocale,
    opened,
    countries,
    langCode,
    hostCountryName,
    availableLanguages,
    localCompanyCode,
    countryPhoneNumber,
    contactFormLink,
  }
}
